import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Form, Input, Label, CardFooter, Button, FormFeedback, FormText, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import './addEmprendimiento.styles.css';
import { uploadSingleImage } from '../../../services/uploadFile';
import { getAllCities } from '../../../redux/actions/citiesActions';
import { addEmprendimiento } from '../../../redux/actions/emprendActions';
import { Redirect } from 'react-router-dom';
//Utils
import filterCities from '../../../utils/departcities';
import classnames from 'classnames';

import swal from 'sweetalert';

class AddEmprendimiento extends Component {

	constructor(props) {
		super(props);
		this.state = this.setInitialData();
	}

	componentDidMount = () => {
		this.props.getAllCities();
	}

	setInitialData = () => {
		return {
			activeTab: '1',
			fullName: "",
			authorizingContact: "",
			authorizingDocument: "",
			phone: "",
			cellphone: "",
			cellphoneTwo: "",
			city: "",
			department: "",
			neighborhood: "",
			direction: "",
			sector: "",
			profilePicture: {},
			documentationFiles: null,
			websiteLink: "",
			permission: "No",
			paymentSystem: "",
			observations: "",
			citiesList: [],
			documentArr: [{
				docName: "",
				path: []
			}],
			personsArr: [{
				fullname: "",
				cellphone: 0,
				email: "",
				position: ""
			}],
			imagePath: [],
			documents: '',
			errors: {
				photoErr: {},
				fullNameErr: {},
				phoneErr: {},
				documentErr: {},
				cellphoneErr: {},
				addressErr: {},
				sectorErr: {},
				authorizingContactErr: {},
				authorizingDocumentErr: {}
			}
		}
	}

	handleChange = (e) => {
		const newState = this.state;
		newState[e.target.id] = e.target.value;
		this.setState(newState);

		if (e.target.id === 'department') {
			this.setState({
				citiesList: filterCities(e.target.value)
			});
		}
	}

	handleChangePositionsObj = (e, key, i) => {
		const newState = this.state;
		newState[e.target.id][i][key] = e.target.value;
		this.setState(newState);
	}

	handleChangeFile = e => {
		if (e.target.files[0]) {
			const imageFile = e.target.files[0];
			this.setState({
				imagePath: imageFile
			});
		}
	};

	handleChangeMultiFile = (e, key, i) => {
		if (e.target.files[0]) {
			const imageFile = e.target.files[0];
			this.state.documentArr[i][key] = imageFile
		}
	}

	addDocuments = () => {
		var tempDocs = this.state.documentArr;
		tempDocs.push({
			docName: "",
			path: []
		});
		this.setState({
			documentArr: tempDocs
		});
	}

	addPeople = () => {
		var tempPersons = this.state.personsArr;
		tempPersons.push({
			fullname: "",
			cellphone: 0,
			email: "",
			position: ""
		});
		this.setState({
			personsArr: tempPersons
		});
	}

	rmPeople = (item, index) => {
		var tempPerson = this.state.personsArr;
		var i = tempPerson.indexOf(item);
		if (i !== -1) {
			tempPerson.splice(i, 1);
			this.setState({
				personsArr: tempPerson
			});
		}
	}

	rmDocuments = (item, index) => {
		var tempDocs = this.state.documentArr;
		var i = tempDocs.indexOf(item);
		if (i !== -1) {
			tempDocs.splice(i, 1);
			this.setState({
				documentArr: tempDocs
			});
		}
	}

	addPositions = () => {
		var tempPosition = this.state.positions;
		tempPosition.push({
			positionName: "",
			companyApply: ""
		});
		this.setState({
			positions: tempPosition
		});
	}

	rmPositions = (item, index) => {
		if (this.state.positions.length > 1) {
			let tempPositions = this.state.positions;
			let i = tempPositions.indexOf(item);
			if (i !== -1) {
				tempPositions.splice(i, 1);
				this.setState({
					Positions: tempPositions
				});
			}
		}
	}

	onSend = async () => {
		const isValid = this.validateForm();
		if (isValid) {
			this.checkInfoToSave();
		} else {
			swal('Lo sentimos!', 'Por favor verifica la información de los campos obligatorios antes de continuar.', 'warning');
			this.setState({
				activeTab: '1'
			});
		}
	};

	checkInfoToSave = () => {
		if (this.state.documentArr.length !== 0 && this.state.documentArr[0].docName !== '') {
			var documents = [];
			this.state.documentArr.forEach(async (item, index) => {
				var data = await uploadSingleImage(item.path);
				documents.push({
					docName: item.docName,
					url: data.file
				});

				if (this.state.documentArr.length === documents.length) {
					this.setState({
						documentationFiles: documents
					});
					this.saveEmprendimiento();
				}
			});
		} else {
			this.saveEmprendimiento();
		}
	}

	saveEmprendimiento = () => {
		var objEmprend = {
			profilePicture: null,
			fullName: this.state.fullName,
			authorizingContact: this.state.authorizingContact,
			authorizingDocument: this.state.authorizingDocument,
			phone: this.state.phone,
			cellphone: this.state.cellphone,
			cellphoneTwo: this.state.cellphoneTwo,
			websiteLink: this.state.websiteLink,
			department: this.state.department,
			neighborhood: this.state.neighborhood,
			city: this.state.city,
			direction: this.state.direction,
			sector: this.state.sector,
			permission: this.state.permission,
			paymentSystem: this.state.paymentSystem,
			observations: this.state.observations,
			linkedPeople: JSON.stringify(this.state.personsArr),
			dataSheet: this.state.documentationFiles !== null ? JSON.stringify(this.state.documentationFiles) : null,
			ally: this.props.allyData[0].idAdvisor
		}
		if (typeof (this.state.imagePath) === 'object' && this.state.imagePath.name) {
			uploadSingleImage(this.state.imagePath).then((data) => {
				objEmprend.profilePicture = data.file;
				//Create emprendimiento	
				this.props.addEmprendimiento(objEmprend).then(resp => {
					this.confirmSave();
				});
			});
		} else {
			//Create emprendimiento
			this.props.addEmprendimiento(objEmprend).then(resp => {
				this.confirmSave();
			});
		}
	}

	confirmSave = () => {
		swal('Genial!', 'El emprendimiento se guardo correctamente.', 'success');
		this.setInitialData();
	}

	toggleTab = (index) => {
		this.setState({
			activeTab: index
		});
	}

	onNextTab = () => {
		let i = parseInt(this.state.activeTab);
		i = i + 1;
		this.setState({
			activeTab: i.toString()
		});
	}

	validateForm = () => {
		const photoErr = {};
		const fullNameErr = {};
		const phoneErr = {};
		const cellphoneErr = {};
		const addressErr = {};
		const sectorErr = {};
		const authorizingContactErr = {};
		const authorizingDocumentErr = {};
		let isValid = true;

		if (this.state.fullName.trim().length === 0) {
			fullNameErr.required = "Por favor ingresa el nombre completo.";
			isValid = false;
		}
		if (this.state.authorizingContact.trim().length === 0) {
			authorizingContactErr.required = "Por favor ingresa un contacto autorizante";
			isValid = false;
		}
	/* 	if (this.state.authorizingDocument.length === 0) {
			authorizingContactErr.required = "Por favor ingresa el documento del contacto autorizante";
			isValid = false;
		} */
		// if (this.state.phone.toString().length !== 7) {
		// 	phoneErr.maxLength = "Por favor ingresa un número de telefono valido.";
		// 	isValid = false;
		// }
		// if (this.state.cellphone.toString().length !== 10) {
		// 	cellphoneErr.required = "Número de celular no valido.";
		// 	isValid = false;
		// }
		if (this.state.direction.length === 0) {
			addressErr.required = "Por favor ingresa la dirección.";
			isValid = false;
		}
		if (this.state.sector === "") {
			sectorErr.required = "Por favor ingresa la información del sector.";
			isValid = false;
		}

		this.setState({
			errors: {
				photoErr,
				fullNameErr,
				// phoneErr,
				// cellphoneErr,
				addressErr,
				sectorErr,
				authorizingContactErr,
				authorizingDocumentErr
			}
		});

		return isValid;
	}

	render() {
		return (
			<div className="backgroundHojaVida">
				{ this.props.isSaveEmprend === true ? <Redirect to="viewEmprendimiento" /> : ''}
				<Row style={{ width: '100%', justifyContent: 'center' }}>
					<Col xs="12" md="12">
						<Card>
							<CardHeader>
								<strong>Emprendimiento</strong>
							</CardHeader>
							<CardBody style={{ overflowY: 'scroll' }}>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={classnames({ active: this.state.activeTab === '1' })}
											onClick={() => { this.toggleTab('1') }}
										>
											Información General Emprendimiento
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: this.state.activeTab === '2' })}
											onClick={() => { this.toggleTab('2') }}
										>
											Personas Vinculadas
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: this.state.activeTab === '3' })}
											onClick={() => { this.toggleTab('3') }}
										>
											Ficha Técnica Producto / Servicio
										</NavLink>
									</NavItem>
								</Nav>
								<Form className="form-horizontal">
									<TabContent activeTab={this.state.activeTab}>
										<TabPane tabId="1">
											<br />
											<Row>
												<Col xs="12" md="6">
													<div className="form-group row containerFoto">
														<label className="col-sm-12" htmlFor="exampleFormControlFile1">Logo Emprendimiento</label>
														<div className="col-sm-10 containerFile">
															<input
																type="file"
																accept="image/x-png,image/jpeg"
																className="form-control-file"
																id="profilePicture"
																onChange={this.handleChangeFile}
															/>
														</div>
														{Object.keys(this.state.errors.photoErr).map((keyObj, index) => (
															<div className="paddingTxtError">
																<FormText className="errorFormText" key={index.toString()}>{this.state.errors.photoErr[keyObj]}</FormText>
															</div>
														))}
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Nombre del Emprendimiento</label>
														<div className="col-sm-10">
															<input
																type="text"
																className="form-control"
																placeholder="Ingresa el nombre del emprendimiento"
																id="fullName"
																onChange={this.handleChange} />
														</div>
														{Object.keys(this.state.errors.fullNameErr).map((keyObj, index) => (
															<div className="paddingTxtError">
																<FormText className="errorFormText" key={index.toString()}>{this.state.errors.fullNameErr[keyObj]}</FormText>
															</div>
														))}
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Contacto Autorizante</label>
														<div className="col-sm-10">
															<input
																type="text"
																className="form-control"
																placeholder="Ingresa el contacto autorizante"
																id="authorizingContact"
																onChange={this.handleChange} />
														</div>
														{Object.keys(this.state.errors.authorizingContactErr).map((keyObj, index) => (
															<div className="paddingTxtError">
																<FormText className="errorFormText" key={index.toString()}>{this.state.errors.authorizingContactErr[keyObj]}</FormText>
															</div>
														))}
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Documento Contacto Autorizante</label>
														<div className="col-sm-10">
															<input
																type="text"
																className="form-control"
																placeholder="Ingresa el documento del contacto autorizante"
																id="authorizingDocument"
																onChange={this.handleChange} />
														</div>
														{Object.keys(this.state.errors.authorizingDocumentErr).map((keyObj, index) => (
															<div className="paddingTxtError">
																<FormText className="errorFormText" key={index.toString()}>{this.state.errors.authorizingDocumentErr[keyObj]}</FormText>
															</div>
														))}
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Teléfono Fijo</label>
														<div className="col-sm-10">
															<input
																type="number"
																className="form-control"
																placeholder="Ingresa número de teléfono"
																id="phone"
																onChange={this.handleChange} />
														</div>
														{/* {Object.keys(this.state.errors.phoneErr).map((keyObj, index) => (
															<div className="paddingTxtError">
																<FormText className="errorFormText" key={index.toString()}>{this.state.errors.phoneErr[keyObj]}</FormText>
															</div>
														))} */}
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Celular</label>
														<div className="col-sm-10">
															<input
																type="number"
																min="0"
																className="form-control"
																placeholder="Ingresa el número de celular"
																id="cellphone"
																onChange={this.handleChange} />
														</div>
														{/* {Object.keys(this.state.errors.cellphoneErr).map((keyObj, index) => (
															<div className="paddingTxtError">
																<FormText className="errorFormText" key={index.toString()}>{this.state.errors.cellphoneErr[keyObj]}</FormText>
															</div>
														))} */}
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Celular 2</label>
														<div className="col-sm-10">
															<input
																type="number"
																min="0"
																className="form-control"
																placeholder="Ingresa otro número de celular"
																id="cellphoneTwo"
																onChange={this.handleChange} />
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Página Web</label>
														<div className="col-sm-10">
															<input
																type="text"
																className="form-control"
																placeholder="Link de la página web"
																id="websiteLink"
																onChange={this.handleChange} />
														</div>
													</div>
												</Col>
												<Col xs="12" md="6">
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Departamento</label>
														<div className="col-sm-10">
															<select
																className="form-control"
																id="department"
																onChange={this.handleChange}
																value={this.state.department}
															>
																<option defaultValue="">Seleccione el departamento</option>
																<option value="Amazonas">Amazonas</option>
																<option value="Antioquia">Antioquia</option>
																<option value="Arauca">Arauca</option>
																<option value="Atlántico">Atlántico</option>
																<option value="Bogotá">Bogotá</option>
																<option value="Bolívar">Bolívar</option>
																<option value="Boyacá">Boyacá</option>
																<option value="Caldas">Caldas</option>
																<option value="Caquetá">Caquetá</option>
																<option value="Casanare">Casanare</option>
																<option value="Cauca">Cauca</option>
																<option value="Cesar">Cesar</option>
																<option value="Chocó">Chocó</option>
																<option value="Córdoba">Córdoba</option>
																<option value="Cundinamarca">Cundinamarca</option>
																<option value="Güainia">Güainia</option>
																<option value="Guaviare">Guaviare</option>
																<option value="Huila">Huila</option>
																<option value="La Guajira">La Guajira</option>
																<option value="Magdalena">Magdalena</option>
																<option value="Meta">Meta</option>
																<option value="Nariño">Nariño</option>
																<option value="Norte de Santander">Norte de Santander</option>
																<option value="Putumayo">Putumayo</option>
																<option value="Quindío">Quindío</option>
																<option value="Risaralda">Risaralda</option>
																<option value="San Andrés y Providencia">San Andrés y Providencia</option>
																<option value="Santander">Santander</option>
																<option value="Sucre">Sucre</option>
																<option value="Tolima">Tolima</option>
																<option value="Valle del Cauca">Valle del Cauca</option>
																<option value="Vaupés">Vaupés</option>
																<option value="Vichada">Vichada</option>
															</select>
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Ciudad</label>
														<div className="col-sm-10">
															<select
																className="form-control"
																id="city"
																onChange={this.handleChange}
															>
																<option defaultValue="" disabled>Seleccione la ciudad</option>
																{this.state.citiesList.length != 0 ? this.state.citiesList.map((item, index) => { return <option key={index.toString()} value={item}>{item}</option> }) : null}
															</select>
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Sector o Barrio</label>
														<div className="col-sm-10">
															<input
																type="text"
																className="form-control"
																placeholder="indica el sector o barrio"
																id="neighborhood"
																onChange={this.handleChange} />
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Dirección</label>
														<div className="col-sm-10">
															<input
																type="text"
																className="form-control"
																placeholder="Ingresa dirección o ubicación emprendimiento"
																id="direction"
																onChange={this.handleChange} />
														</div>
														{Object.keys(this.state.errors.addressErr).map((keyObj, index) => (
															<div className="paddingTxtError">
																<FormText className="errorFormText" key={index.toString()}>{this.state.errors.addressErr[keyObj]}</FormText>
															</div>
														))}
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Sector Económico</label>
														<div className="col-sm-10">
															<Input
																type="select"
																className="form-contol"
																id="sector"
																onChange={this.handleChange}
																value={this.state.sector}
															>
																<option defaultValue="">Seleccionar sector</option>
																<option>Artesanías</option>
																<option>Confección</option>
																<option>Turismo</option>
																<option>Industria</option>
															</Input>
														</div>
														{Object.keys(this.state.errors.sectorErr).map((keyObj, index) => (
															<div className="paddingTxtError">
																<FormText className="errorFormText" key={index.toString()}>{this.state.errors.sectorErr[keyObj]}</FormText>
															</div>
														))}
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Permiso</label>
														<div className="col-sm-10">
															<Input
																type="select"
																className="form-contol"
																id="permission"
																onChange={this.handleChange}
																value={this.state.permission}
															>
																<option>Si</option>
																<option>No</option>
															</Input>
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Formalizado (sistemas de pago)</label>
														<div className="col-sm-10">
															<input
																type="text"
																className="form-control"
																placeholder="Ingresa el sistema o forma de pago"
																id="paymentSystem"
																onChange={this.handleChange} />
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-12 col-form-label">Observaciones</label>
														<div className="col-sm-10">
															<Input
																type="textarea"
																rows="4"
																className="form-control"
																placeholder="Observaciones generales"
																id="observations"
																onChange={this.handleChange} />
														</div>
													</div>
												</Col>
											</Row>
										</TabPane>
										<TabPane tabId="2">
											<br />
											<div className="row containerPlus"
												onClick={() => this.addPeople()}
											>
												<Button color="info" type="button">Agregar</Button>
											</div>
											<Row>
												{this.state.personsArr.map((item, index) => (
													<Col xs="12" md="6">
														<div
															className="contentCloseDocuments"
															style={{ width: '85%', cursor: 'pointer' }}
															onClick={() => this.rmPeople(item, index)}
														>
															<a className="btnPlus">
																<img src={require('../../../assets/Icons/close.png')} alt="Eliminar" className="iconPlus" />
															</a>
														</div>
														<div className="form-group row">
															<label className="col-sm-12 col-form-label">Nombre Completo</label>
															<div className="col-sm-10">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Ingresa nombre completo"
																	id="personsArr"
																	onChange={(e) => this.handleChangePositionsObj(e, 'fullname', index)} />
															</div>
														</div>
														<div className="form-group row">
															<label className="col-sm-12 col-form-label">Celular</label>
															<div className="col-sm-10">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Ingresa el número de celular"
																	id="personsArr"
																	onChange={(e) => this.handleChangePositionsObj(e, 'cellphone', index)} />
															</div>
														</div>
														<div className="form-group row">
															<label className="col-sm-12 col-form-label">Correo electronico</label>
															<div className="col-sm-10">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Ingresa el correo electrónico"
																	id="personsArr"
																	onChange={(e) => this.handleChangePositionsObj(e, 'email', index)} />
															</div>
														</div>
														<div className="form-group row">
															<label className="col-sm-12 col-form-label">Cargo en emprendimiento</label>
															<div className="col-sm-10">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Ingresa el cargo"
																	id="personsArr"
																	onChange={(e) => this.handleChangePositionsObj(e, 'position', index)} />
															</div>
														</div>
														<hr></hr>
													</Col>
												))}
											</Row>
										</TabPane>
										<TabPane tabId="3">
											<br />
											<div className="row containerPlus"
												onClick={() => this.addDocuments()}
											>
												<Button color="info" type="button">Agregar</Button>
											</div>
											{this.state.documentArr.map((item, index) => {
												return (
													<div className="form-group row containerArchivos" key={index.toString()}>
														<label className="col-sm-2" htmlFor="exampleFormControlFile1">Subir Archivos</label>
														<div className="col-sm-10 containerFileDocuments">
															<div
																className="contentCloseDocuments"
																onClick={() => this.rmDocuments(item, index)}
															>
																<a className="btnPlus">
																	<img src={require('../../../assets/Icons/close.png')} alt="Eliminar" className="iconPlus" />
																</a>
															</div>
															<div className="row contentDocumentos">
																<div className="col-sm-6">
																	<select
																		className="form-control"
																		id="documentArr"
																		onChange={(e) => this.handleChangePositionsObj(e, 'docName', index)}
																	>
																		<option value="" disabled selected>Selecciona archivo</option>
																		<option value="producto">Producto</option>
																		<option value="servicio">Servicio</option>
																	</select>
																</div>

																<div className="col-sm-6">
																	<input
																		type="file"
																		className="form-control-file"
																		id="exampleFormControlFile1"
																		onChange={(e) => this.handleChangeMultiFile(e, 'path', index)} />
																</div>
															</div>
														</div>
													</div>
												)
											})}
										</TabPane>
									</TabContent>
								</Form>
							</CardBody>
							<CardFooter>
								{this.state.activeTab == "3" && <div className="col-12 containerBtnEnviar">
									<button className="btn-success btnEnviar" onClick={() => this.onSend()}>
										ENVIAR
									</button>
								</div>}
								{this.state.activeTab != "3" && <div className="col-12 containerBtnEnviar">
									<button className="btn-success btnEnviar" onClick={() => this.onNextTab()}>
										SIGUIENTE
									</button>
								</div>}
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}

}

const mapStateToProps = state => {
	return {
		citiesData: state.citiesReducer.citiesData,
		allyData: state.allyReducer.allyData,
		isSaveEmprend: state.emprendimientoReducer.isSaveEmprend
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllCities: bindActionCreators(getAllCities, dispatch),
		addEmprendimiento: bindActionCreators(addEmprendimiento, dispatch)
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(AddEmprendimiento);