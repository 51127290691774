import React, { Component, Fragment } from "react";
import { Table, Col, FormGroup, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../header/Header";
import {
  getAllCandidatesBD,
  setCandidate,
  setCount,
} from "../../redux/actions/candidateActions";
import Pagination from "react-js-pagination";

function CandidateRow(props) {
  const candidate = props.candidate;
  console.log(candidate);

  return (
    <tr id="tr" key="">
      <td scope="row">{candidate.document}</td>
      <td>{candidate.fullName}</td>
      <td>
        {candidate.positions[0] ? candidate.positions[0].nameVacancy : "N/A"}
      </td>
      <td>
        {candidate.positions[0] ? candidate.positions[0].nameClient : "N/A"}
      </td>
      <td>{candidate.gender}</td>
      <td>{candidate.allyName}</td>
      <td>{candidate.timestamp.split("T")[0]}</td>
    </tr>
  );
}

class ListaUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.setCount(0);
    this.props.getAllCandidatesBD(1);
    this.props.setCandidate([]);
  };

  goToDetail = () => {};

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  handlePageChange = (e) => {
    window.scroll(0, 0);
    this.props.getAllCandidatesBD(e);

    this.setState({
      activePage: e,
    });
  };

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value),
    });
  };

  renderOptionsPage = () => {
    let options = [];
    if (this.props.pageCount !== 0) {
      let base = 2;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>
                {i}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Base de datos
              </strong>
            </p>
            <p className="subtitleSeguimiento">
              Listado de todos los candidatos pertenecientes a Alianza
              Soluciones
            </p>
          </div>

          <a
            href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/`}
            target="_blank"
            style={{ display: "inline" }}
          >
            <Button color="info">Descargar</Button>
          </a>
          <a
            href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?state=11`}
            target="_blank"
            style={{ display: "inline", marginLeft: 10 }}
          >
            <Button color="info">Descargar Vinculados</Button>
          </a>
          <a
            href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?state=14`}
            target="_blank"
            style={{ display: "inline", marginLeft: 10 }}
          >
            <Button color="info">Descargar Desvinculados</Button>
          </a>
          <a
            href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?state=12`}
            target="_blank"
            style={{ display: "inline", marginLeft: 10 }}
          >
            <Button color="info">Descargar Vinculado Aprendiz</Button>
          </a>
          <a
            href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?state=13`}
            target="_blank"
            style={{ display: "inline", marginLeft: 10 }}
          >
            <Button color="info">Descargar Vinculado Reintegro</Button>
          </a>
          <br />

          <div className="table-responsiveAprobados containerResumenSeleccion">
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Cédula</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Cargo</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Genero</th>
                  <th scope="col">Programa / Agencia</th>
                  <th scope="col">Fecha ingreso</th>
                </tr>
              </thead>
              <tbody>
                {this.props.candidatesBusiness.length !== 0
                  ? this.props.candidatesBusiness.results.map((item, index) => {
                      return (
                        <CandidateRow
                          key={index.toString()}
                          module={this.props.modulePath}
                          candidate={item}
                          date={this.formatDate(
                            parseInt(item.admissionDate) * 1000
                          )}
                          onEdit={() => this.goToDetail()}
                        />
                      );
                    })
                  : ""}
              </tbody>
            </Table>
          </div>

          <Col
            md={12}
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.pageCount}
              pageRangeDisplayed={2}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
            />
            {/* {this.renderOptionsPage()} */}
          </Col>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    candidatesBusiness: state.candidateReducer.candidatesBusiness,
    isLoading: state.candidateReducer.isLoading,
    modulePath: state.allyReducer.modulePath,
    pageCount: state.candidateReducer.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCandidatesBD: bindActionCreators(getAllCandidatesBD, dispatch),
    setCandidate: bindActionCreators(setCandidate, dispatch),
    setCount: bindActionCreators(setCount, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaUsuarios);
