const filterCities = (valor) => {
  switch (valor) {
    case 'Amazonas':
      return ['Leticia', 'El encanto', 'La chorrera', 'La pedrera', 'La victoria', 'Miriti-parana', 'Puerto alegria', 'Puerto arica', 'Puerto nariño', 'Santander', 'Tarapaca'];
    case 'Antioquia':
      return ['Abejorral', 'Abriaqui', 'Alejandria', 'Amaga', 'Amalfi', 'Andes', 'Angelopolis', 'Angostura', 'Anori', 'Anza', 'Apartado', 'Arboletes', 'Argelia', 'Armenia', 'Barbosa', 'Bello', 'Belmira', 'Betania', 'Betulia', 'Briceno', 'Buritica', 'Caceres', 'Caicedo', 'Caldas', 'Campamento', 'Canasgordas', 'Caracoli', 'Caramanta', 'Carepa', 'Carmen de viboral', 'Carolina', 'Caucasia', 'Chigorodo', 'Cisneros', 'Ciudad Bolivar', 'Cocorna', 'Concepción', 'Concordia', 'Copacabana', 'Dabeiba', 'Don Matias', 'Ebejico', 'El bagre', 'Entrerios', 'Envigado', 'Fredonia', 'Frontino', 'Giraldo', 'Girardota', 'Gomez plata', 'Granada', 'Guadalupe', 'Guarne', 'Guatape', 'Heliconia', 'Hispania', 'Itagüi', 'Ituanguo', 'Jardín', 'Jerico', 'Laceja', 'La estrella', 'La pintada', 'la unión', 'Liborina', 'Maceo', 'Marinilla', 'Medellín', 'Montebello', 'Murindo', 'Mutata', 'Nariño', 'Nechi', 'Necocli', 'Olaya', 'Peñol', 'Peque', 'Pueblo rico', 'Puerto berrio', 'Puertonare', 'Puerto triunfo', 'Remedios', 'Retiro', 'Rionegro', 'Sabana Larga', 'Sabaneta', 'Salgar', 'San Andres', 'San Carlos', 'San Francisco', 'San jeronimo', 'San José de la montaña', 'San Juan de Uraba', 'San Luis', 'San Pedro', 'San Pedro de Uraba', 'San Rafael', 'San Roque', 'San Vicente', 'Santa Barbara', 'Santa fe de Antioquia', 'Santa Rosa de Osos', 'Santo Domingo', 'Santuario', 'Segovia', 'Sonson', 'Sopetran', 'Tamesis', 'Taraza', 'Tarzo', 'Titiribe', 'Toledo', 'Turbo', 'Uramita', 'Urrao', 'Valdivia', 'Valparaiso', 'Vegachi', 'Venecia', 'Vigia del Fuerte', 'Yali', 'Yarumal', 'Yolombo', 'Yondo', 'Zaragoza'];
    case 'Arauca':
      return ['Arauca', 'Arauquita', 'Cravo norte', 'Fortul', 'Puerto Rondon', 'Saravena', 'Tame'];
    case 'Atlántico':
      return ['Baranda', 'Barranquilla', 'Campo de la cruz', 'Candelaria', 'Galapa', 'Luruaco', 'Ponedera', 'Puerto Colombia', 'Repelon', 'Sabanagrande', 'Sabanalarga', 'Santa Lucia', 'Santo tomas', 'Soledad', 'Suan', 'Tubara', 'Usiaguri'];
    case 'Bolívar':
      return ["Ach\u00ed",
        "Altos del Rosario",
        "Arenal",
        "Arjona",
        "Arroyohondo",
        "Barranco de Loba",
        "Brazuelo de Papayal",
        "Calamar",
        "Cantagallo",
        "Cartagena de Indias",
        "Cicuco",
        "Clemencia",
        "Córdoba",
        "El Carmen de Bol\u00edvar",
        "El Guamo",
        "El Peñón",
        "Hatillo de Loba",
        "Magangué",
        "Mahates",
        "Margarita",
        "Mar\u00eda la Baja",
        "Mompós",
        "Montecristo",
        "Morales",
        "Noros\u00ed",
        "Pinillos",
        "Regidor",
        "R\u00edo Viejo",
        "San Cristóbal",
        "San Estanislao",
        "San Fernando",
        "San Jacinto del Cauca",
        "San Jacinto",
        "San Juan Nepomuceno",
        "San Mart\u00edn de Loba",
        "San Pablo",
        "Santa Catalina",
        "Santa Rosa",
        "Santa Rosa del Sur",
        "Simit\u00ed",
        "Soplaviento",
        "Talaigua Nuevo",
        "Tiquisio",
        "Turbaco",
        "Turbaná",
        "Villanueva",
        "Zambrano"
      ];

    case 'Boyacá':
      return ["Almeida",
        "Aquitania",
        "Arcabuco",
        "Belén",
        "Berbeo",
        "Betéitiva",
        "Boavita",
        "Boyacá",
        "Briceño",
        "Buenavista",
        "Busbanzá",
        "Caldas",
        "Campohermoso",
        "Cerinza",
        "Chinavita",
        "Chiquinquirá",
        "Ch\u00edquiza",
        "Chiscas",
        "Chita",
        "Chitaraque",
        "Chivatá",
        "Chivor",
        "Ciénega",
        "Cómbita",
        "Coper",
        "Corrales",
        "Covarach\u00eda",
        "Cubará",
        "Cucaita",
        "Cu\u00edtiva",
        "Duitama",
        "El Cocuy",
        "El Espino",
        "Firavitoba",
        "Floresta",
        "Gachantivá",
        "Gámeza",
        "Garagoa",
        "Guacamayas",
        "Guateque",
        "Guayatá",
        "G\u00fcicán",
        "Iza",
        "Jenesano",
        "Jericó",
        "La Capilla",
        "La Uvita",
        "La Victoria",
        "Labranzagrande",
        "Macanal",
        "Marip\u00ed",
        "Miraflores",
        "Mongua",
        "Mongu\u00ed",
        "Moniquirá",
        "Motavita",
        "Muzo",
        "Nobsa",
        "Nuevo Colón",
        "Oicatá",
        "Otanche",
        "Pachavita",
        "Páez",
        "Paipa",
        "Pajarito",
        "Panqueba",
        "Pauna",
        "Paya",
        "Paz del R\u00edo",
        "Pesca",
        "Pisba",
        "Puerto Boyacá",
        "Qu\u00edpama",
        "Ramiriqu\u00ed",
        "Ráquira",
        "Rondón",
        "Saboyá",
        "Sáchica",
        "Samacá",
        "San Eduardo",
        "San José de Pare",
        "San Luis de Gaceno",
        "San Mateo",
        "San Miguel de Sema",
        "San Pablo de Borbur",
        "Santa Mar\u00eda",
        "Santa Rosa de Viterbo",
        "Santa Sof\u00eda",
        "Santana",
        "Sativanorte",
        "Sativasur",
        "Siachoque",
        "Soatá",
        "Socha",
        "Socotá",
        "Sogamoso",
        "Somondoco",
        "Sora",
        "Soracá",
        "Sotaquirá",
        "Susacón",
        "Sutamarchán",
        "Sutatenza",
        "Tasco",
        "Tenza",
        "Tibaná",
        "Tibasosa",
        "Tinjacá",
        "Tipacoque",
        "Toca",
        "Tog\u00fc\u00ed",
        "Tópaga",
        "Tota",
        "Tunja",
        "Tununguá",
        "Turmequé",
        "Tuta",
        "Tutazá",
        "\u00dambita",
        "Ventaquemada",
        "Villa de Leyva",
        "Viracachá",
        "Zetaquira"
      ];


    case 'Caldas':
      return ["Aguadas",
        "Anserma",
        "Aranzazu",
        "Belalcázar",
        "Chinchiná",
        "Filadelfia",
        "La Dorada",
        "La Merced",
        "Manizales",
        "Manzanares",
        "Marmato",
        "Marquetalia",
        "Marulanda",
        "Neira",
        "Norcasia",
        "Pácora",
        "Palestina",
        "Pensilvania",
        "Riosucio",
        "Risaralda",
        "Salamina",
        "Samaná",
        "San José",
        "Sup\u00eda",
        "Victoria",
        "Villamar\u00eda",
        "Viterbo"
      ];

    case 'Caquetá':
      return ["Albania",
        "Belén de los Andaqu\u00edes",
        "Cartagena del Chairá",
        "Curillo",
        "El Doncello",
        "El Paujil",
        "Florencia",
        "La Montañita",
        "Milán",
        "Morelia",
        "Puerto Rico",
        "San José del Fragua",
        "San Vicente del Caguán",
        "Solano",
        "Solita",
        "Valpara\u00edso"
      ];

    case 'Casanare':
      return ["Aguazul",
        "Chámeza",
        "Hato Corozal",
        "La Salina",
        "Man\u00ed",
        "Monterrey",
        "Nunch\u00eda",
        "Orocué",
        "Paz de Ariporo",
        "Pore",
        "Recetor",
        "Sabanalarga",
        "Sácama",
        "San Luis de Palenque",
        "Támara",
        "Tauramena",
        "Trinidad",
        "Villanueva",
        "Yopal"
      ];


    case 'Cauca':
      return ["Almaguer",
        "Argelia",
        "Balboa",
        "Bol\u00edvar",
        "Buenos Aires",
        "Cajib\u00edo",
        "Caldono",
        "Caloto",
        "Corinto",
        "El Tambo",
        "Florencia",
        "Guachené",
        "Guap\u00ed",
        "Inzá",
        "Jambaló",
        "La Sierra",
        "La Vega",
        "López de Micay",
        "Mercaderes",
        "Miranda",
        "Morales",
        "Padilla",
        "Páez",
        "Pat\u00eda",
        "Piamonte",
        "Piendamó",
        "Popayán",
        "Puerto Tejada",
        "Puracé",
        "Rosas",
        "San Sebastián",
        "Santa Rosa",
        "Santander de Quilichao",
        "Silvia",
        "Sotará",
        "Suárez",
        "Sucre",
        "Timb\u00edo",
        "Timbiqu\u00ed",
        "Torib\u00edo",
        "Totoró",
        "Villa Rica"
      ];


    case 'Cesar':
      return ["Aguachica",
        "Agust\u00edn Codazzi",
        "Astrea",
        "Becerril",
        "Bosconia",
        "Chimichagua",
        "Chiriguaná",
        "Curuman\u00ed",
        "El Copey",
        "El Paso",
        "Gamarra",
        "González",
        "La Gloria (Cesar)",
        "La Jagua de Ibirico",
        "La Paz",
        "Manaure Balcón del Cesar",
        "Pailitas",
        "Pelaya",
        "Pueblo Bello",
        "R\u00edo de Oro",
        "San Alberto",
        "San Diego",
        "San Mart\u00edn",
        "Tamalameque",
        "Valledupar"
      ];


    case 'Chocó':
      return ["Acand\u00ed",
        "Alto Baudó",
        "Bagadó",
        "Bah\u00eda Solano",
        "Bajo Baudó",
        "Bojayá",
        "Cantón de San Pablo",
        "Cértegui",
        "Condoto",
        "El Atrato",
        "El Carmen de Atrato",
        "El Carmen del Darién",
        "Istmina",
        "Juradó",
        "Litoral de San Juan",
        "Lloró",
        "Medio Atrato",
        "Medio Baudó",
        "Medio San Juan",
        "Nóvita",
        "Nuqu\u00ed",
        "Quibdó",
        "R\u00edo Iró",
        "R\u00edo Quito",
        "Riosucio",
        "San José del Palmar",
        "Sip\u00ed",
        "Tadó",
        "Unión Panamericana",
        "Ungu\u00eda"
      ];


    case 'Cundinamarca':
      return ["Agua de Dios",
        "Albán",
        "Anapoima",
        "Anolaima",
        "Apulo",
        "Arbeláez",
        "Beltrán",
        "Bituima",
        "Bogotá",
        "Bojacá",
        "Cabrera",
        "Cachipay",
        "Cajicá",
        "Caparrap\u00ed",
        "Cáqueza",
        "Carmen de Carupa",
        "Chaguan\u00ed",
        "Ch\u00eda",
        "Chipaque",
        "Choach\u00ed",
        "Chocontá",
        "Cogua",
        "Cota",
        "Cucunubá",
        "El Colegio",
        "El Peñón",
        "El Rosal",
        "Facatativá",
        "Fómeque",
        "Fosca",
        "Funza",
        "F\u00faquene",
        "Fusagasugá",
        "Gachalá",
        "Gachancipá",
        "Gachetá",
        "Gama",
        "Girardot",
        "Granada",
        "Guachetá",
        "Guaduas",
        "Guasca",
        "Guataqu\u00ed",
        "Guatavita",
        "Guayabal de S\u00edquima",
        "Guayabetal",
        "Gutiérrez",
        "Jerusalén",
        "Jun\u00edn",
        "La Calera",
        "La Mesa",
        "La Palma",
        "La Peña",
        "La Vega",
        "Lenguazaque",
        "Machetá",
        "Madrid",
        "Manta",
        "Medina",
        "Mosquera",
        "Nariño",
        "Nemocón",
        "Nilo",
        "Nimaima",
        "Nocaima",
        "Pacho",
        "Paime",
        "Pandi",
        "Paratebueno",
        "Pasca",
        "Puerto Salgar",
        "Pul\u00ed",
        "Quebradanegra",
        "Quetame",
        "Quipile",
        "Ricaurte",
        "San Antonio del Tequendama",
        "San Bernardo",
        "San Cayetano",
        "San Francisco",
        "San Juan de Rioseco",
        "Sasaima",
        "Sesquilé",
        "Sibaté",
        "Silvania",
        "Simijaca",
        "Soacha",
        "Sopó",
        "Subachoque",
        "Suesca",
        "Supatá",
        "Susa",
        "Sutatausa",
        "Tabio",
        "Tausa",
        "Tena",
        "Tenjo",
        "Tibacuy",
        "Tibirita",
        "Tocaima",
        "Tocancipá",
        "Topaip\u00ed",
        "Ubalá",
        "Ubaque",
        "Ubaté",
        "Une",
        "\u00datica",
        "Venecia",
        "Vergara",
        "Vian\u00ed",
        "Villagómez",
        "Villapinzón",
        "Villeta",
        "Viotá",
        "Yacop\u00ed",
        "Zipacón",
        "Zipaquirá"
      ];


    case 'Córdoba':
      return ["Ayapel",
        "Buenavista",
        "Canalete",
        "Cereté",
        "Chimá",
        "Chin\u00fa",
        "Ciénaga de Oro",
        "Cotorra",
        "La Apartada",
        "Lorica",
        "Los Córdobas",
        "Momil",
        "Montel\u00edbano",
        "Monter\u00eda",
        "Moñitos",
        "Planeta Rica",
        "Pueblo Nuevo",
        "Puerto Escondido",
        "Puerto Libertador",
        "Pur\u00edsima",
        "Sahag\u00fan",
        "San Andrés de Sotavento",
        "San Antero",
        "San Bernardo del Viento",
        "San Carlos",
        "San José de Uré",
        "San Pelayo",
        "Tierralta",
        "Tuch\u00edn",
        "Valencia"
      ];
    case 'Guainía':
      return ["In\u00edrida"];
    case 'Guaviare':
      return ["Calamar",
        "El Retorno",
        "Miraflores",
        "San José del Guaviare"
      ];
    case 'Huila':
      return ["Acevedo",
        "Agrado",
        "Aipe",
        "Algeciras",
        "Altamira",
        "Baraya",
        "Campoalegre",
        "Colombia",
        "El Pital",
        "El\u00edas",
        "Garzón",
        "Gigante",
        "Guadalupe",
        "Hobo",
        "\u00cdquira",
        "Isnos",
        "La Argentina",
        "La Plata",
        "Nátaga",
        "Neiva",
        "Oporapa",
        "Paicol",
        "Palermo",
        "Palestina",
        "Pitalito",
        "Rivera",
        "Saladoblanco",
        "San Agust\u00edn",
        "Santa Mar\u00eda",
        "Suaza",
        "Tarqui",
        "Tello",
        "Teruel",
        "Tesalia",
        "Timaná",
        "Villavieja",
        "Yaguará"
      ];
    case 'La Guajira':
      return ["Albania",
        "Barrancas",
        "Dibulla",
        "Distracción",
        "El Molino",
        "Fonseca",
        "Hatonuevo",
        "La Jagua del Pilar",
        "Maicao",
        "Manaure",
        "Riohacha",
        "San Juan del Cesar",
        "Uribia",
        "Urumita",
        "Villanueva"
      ];
    case 'Magdalena':
      return ["Algarrobo",
        "Aracataca",
        "Ariguan\u00ed",
        "Cerro de San Antonio",
        "Chibolo",
        "Chibolo",
        "Ciénaga",
        "Concordia",
        "El Banco",
        "El Piñón",
        "El Retén",
        "Fundación",
        "Guamal",
        "Nueva Granada",
        "Pedraza",
        "Pijiño del Carmen",
        "Pivijay",
        "Plato",
        "Pueblo Viejo",
        "Remolino",
        "Sabanas de San \u00c1ngel",
        "Salamina",
        "San Sebastián de Buenavista",
        "San Zenón",
        "Santa Ana",
        "Santa Bárbara de Pinto",
        "Santa Marta",
        "Sitionuevo",
        "Tenerife",
        "Zapayán",
        "Zona Bananera"
      ];
    case 'Meta':
      return ["Acac\u00edas",
        "Barranca de Up\u00eda",
        "Cabuyaro",
        "Castilla la Nueva",
        "Cubarral",
        "Cumaral",
        "El Calvario",
        "El Castillo",
        "El Dorado",
        "Fuente de Oro",
        "Granada",
        "Guamal",
        "La Macarena",
        "La Uribe",
        "Lejan\u00edas",
        "Mapiripán",
        "Mesetas",
        "Puerto Concordia",
        "Puerto Gaitán",
        "Puerto Lleras",
        "Puerto López",
        "Puerto Rico",
        "Restrepo",
        "San Carlos de Guaroa",
        "San Juan de Arama",
        "San Juanito",
        "San Mart\u00edn",
        "Villavicencio",
        "Vista Hermosa"
      ];
    case 'Nariño':
      return ["Aldana",
        "Ancuyá",
        "Arboleda",
        "Barbacoas",
        "Belén",
        "Buesaco",
        "Chachag\u00fc\u00ed",
        "Colón",
        "Consacá",
        "Contadero",
        "Córdoba",
        "Cuaspud",
        "Cumbal",
        "Cumbitara",
        "El Charco",
        "El Peñol",
        "El Rosario",
        "El Tablón",
        "El Tambo",
        "Francisco Pizarro",
        "Funes",
        "Guachucal",
        "Guaitarilla",
        "Gualmatán",
        "Iles",
        "Imués",
        "Ipiales",
        "La Cruz",
        "La Florida",
        "La Llanada",
        "La Tola",
        "La Unión",
        "Leiva",
        "Linares",
        "Los Andes",
        "Mag\u00fc\u00ed Payán",
        "Mallama",
        "Mosquera",
        "Nariño",
        "Olaya Herrera",
        "Ospina",
        "Pasto",
        "Policarpa",
        "Potos\u00ed",
        "Providencia",
        "Puerres",
        "Pupiales",
        "Ricaurte",
        "Roberto Payán",
        "Samaniego",
        "San Bernardo",
        "San José de Albán",
        "San Lorenzo",
        "San Pablo",
        "San Pedro de Cartago",
        "Sandoná",
        "Santa Bárbara",
        "Santacruz",
        "Sapuyes",
        "Taminango",
        "Tangua",
        "Tumaco",
        "T\u00faquerres",
        "Yacuanquer"
      ];
    case 'Norte de Santander':
      return ["\u00c1brego",
        "Arboledas",
        "Bochalema",
        "Bucarasica",
        "Cáchira",
        "Cácota",
        "Chinácota",
        "Chitagá",
        "Convención",
        "C\u00facuta",
        "Cucutilla",
        "Duran\u00eda",
        "El Carmen",
        "El Tarra",
        "El Zulia",
        "Gramalote",
        "Hacar\u00ed",
        "Herrán",
        "La Esperanza",
        "La Playa de Belén",
        "Labateca",
        "Los Patios",
        "Lourdes",
        "Mutiscua",
        "Ocaña",
        "Pamplona",
        "Pamplonita",
        "Puerto Santander",
        "Ragonvalia",
        "Salazar de Las Palmas",
        "San Calixto",
        "San Cayetano",
        "Santiago",
        "Santo Domingo de Silos",
        "Sardinata",
        "Teorama",
        "Tib\u00fa",
        "Toledo",
        "Villa Caro",
        "Villa del Rosario"
      ];
    case 'Putumayo':
      return ["Colón",
        "Mocoa",
        "Orito",
        "Puerto As\u00eds",
        "Puerto Caicedo",
        "Puerto Guzmán",
        "Puerto Legu\u00edzamo",
        "San Francisco",
        "San Miguel",
        "Santiago",
        "Sibundoy",
        "Valle del Guamuez",
        "Villagarzón"
      ];
    case 'Quindío':
      return ["Armenia",
        "Buenavista",
        "Calarcá",
        "Circasia",
        "Córdoba",
        "Filandia",
        "Génova",
        "La Tebaida",
        "Montenegro",
        "Pijao",
        "Quimbaya",
        "Salento"
      ];
    case 'Risaralda':
      return ["Ap\u00eda",
        "Balboa",
        "Belén de Umbr\u00eda",
        "Dosquebradas",
        "Guática",
        "La Celia",
        "La Virginia",
        "Marsella",
        "Mistrató",
        "Pereira",
        "Pueblo Rico",
        "Quinch\u00eda",
        "Santa Rosa de Cabal",
        "Santuario"
      ];
    case 'San Andrés':
      return ["Providencia y Santa Catalina Islas",
        "San Andrés"
      ];
    case 'Santander':
      return ["Aguada",
        "Albania",
        "Aratoca",
        "Barbosa",
        "Barichara",
        "Barrancabermeja",
        "Betulia",
        "Bol\u00edvar",
        "Bucaramanga",
        "Cabrera",
        "California",
        "Capitanejo",
        "Carcas\u00ed",
        "Cepitá",
        "Cerrito",
        "Charalá",
        "Charta",
        "Chima",
        "Chipatá",
        "Cimitarra",
        "Concepción",
        "Confines",
        "Contratación",
        "Coromoro",
        "Curit\u00ed",
        "El Carmen de Chucur\u00ed",
        "El Guacamayo",
        "El Peñón",
        "El Playón",
        "El Socorro",
        "Encino",
        "Enciso",
        "Florián",
        "Floridablanca",
        "Galán",
        "Gámbita",
        "Girón",
        "Guaca",
        "Guadalupe",
        "Guapotá",
        "Guavatá",
        "G\u00fcepsa",
        "Hato",
        "Jes\u00fas Mar\u00eda",
        "Jordán",
        "La Belleza",
        "La Paz",
        "Landázuri",
        "Lebrija",
        "Los Santos",
        "Macaravita",
        "Málaga",
        "Matanza",
        "Mogotes",
        "Molagavita",
        "Ocamonte",
        "Oiba",
        "Onzaga",
        "Palmar",
        "Palmas del Socorro",
        "Páramo",
        "Piedecuesta",
        "Pinchote",
        "Puente Nacional",
        "Puerto Parra",
        "Puerto Wilches",
        "Rionegro",
        "Sabana de Torres",
        "San Andrés",
        "San Benito",
        "San Gil",
        "San Joaqu\u00edn",
        "San José de Miranda",
        "San Miguel",
        "San Vicente de Chucur\u00ed",
        "Santa Bárbara",
        "Santa Helena del Opón",
        "Simacota",
        "Suaita",
        "Sucre",
        "Suratá",
        "Tona",
        "Valle de San José",
        "Vélez",
        "Vetas",
        "Villanueva",
        "Zapatoca"
      ];
    case 'Sucre':
      return ["Buenavista",
        "Caimito",
        "Chalán",
        "Colosó",
        "Corozal",
        "Coveñas",
        "El Roble",
        "Galeras",
        "Guaranda",
        "La Unión",
        "Los Palmitos",
        "Majagual",
        "Morroa",
        "Ovejas",
        "Sampués",
        "San Antonio de Palmito",
        "San Benito Abad",
        "San Juan de Betulia",
        "San Marcos",
        "San Onofre",
        "San Pedro",
        "Sincé",
        "Sincelejo",
        "Sucre",
        "Tol\u00fa",
        "Tol\u00fa Viejo"
      ];
    case 'Tolima':
      return ["Alpujarra",
        "Alvarado",
        "Ambalema",
        "Anzoátegui",
        "Armero",
        "Ataco",
        "Cajamarca",
        "Carmen de Apicalá",
        "Casabianca",
        "Chaparral",
        "Coello",
        "Coyaima",
        "Cunday",
        "Dolores",
        "El Espinal",
        "Falán",
        "Flandes",
        "Fresno",
        "Guamo",
        "Herveo",
        "Honda",
        "Ibagué",
        "Icononzo",
        "Lérida",
        "L\u00edbano",
        "Mariquita",
        "Melgar",
        "Murillo",
        "Natagaima",
        "Ortega",
        "Palocabildo",
        "Piedras",
        "Planadas",
        "Prado",
        "Purificación",
        "Rioblanco",
        "Roncesvalles",
        "Rovira",
        "Saldaña",
        "San Antonio",
        "San Luis",
        "Santa Isabel",
        "Suárez",
        "Valle de San Juan",
        "Venadillo",
        "Villahermosa",
        "Villarrica"
      ];
    case 'Valle del Cauca':
      return ["Alcalá",
        "Andaluc\u00eda",
        "Ansermanuevo",
        "Argelia",
        "Bol\u00edvar",
        "Buenaventura",
        "Buga",
        "Bugalagrande",
        "Caicedonia",
        "Cali",
        "Calima",
        "Candelaria",
        "Cartago",
        "Dagua",
        "El \u00c1guila",
        "El Cairo",
        "El Cerrito",
        "El Dovio",
        "Florida",
        "Ginebra",
        "Guacar\u00ed",
        "Jamund\u00ed",
        "La Cumbre",
        "La Unión",
        "La Victoria",
        "Obando",
        "Palmira",
        "Pradera",
        "Restrepo",
        "Riofr\u00edo",
        "Roldanillo",
        "San Pedro",
        "Sevilla",
        "Toro",
        "Trujillo",
        "Tuluá",
        "Ulloa",
        "Versalles",
        "Vijes",
        "Yotoco",
        "Yumbo",
        "Zarzal"
      ];
    case 'Vaupés':
      return ["Carur\u00fa",
        "Mit\u00fa",
        "Taraira"
      ];
    case 'Vichada':
      return ["Cumaribo",
        "La Primavera",
        "Puerto Carreño",
        "Santa Rosal\u00eda"
      ];
    case 'Güainia':
      return ["Barrancominas",
        "Cacahual",
        "Inírida",
        "La Guadalupe",
        "Mapiripana",
        "Morichal Nuevo",
        "Pana Pana",
        "Puerto Colombia",
        "San Felipe"
      ];
    case 'San Andrés y Providencia':
      return ["No definida",
        "San Andrés",
        "Providencia"
      ];
    case 'Bogotá':
      return ["Bogotá"];
  }
}

export default filterCities;