import React, { Component } from "react";
import "./addCandidate.styles.css";

import HojaVida from "../../hojaVida/addHojaVida/HojaVida";
import { connect } from "react-redux";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionsTabs: ["1", "2", "3", "4"],
    };
  }

  render() {
    return (
      <div className="backgroundAgregar">
       {/* <div className="headerInicio">
           <div className="allyPicture">
                        {this.props.allyData.length != 0 && <img src={this.props.allyData[0].advisorData.profilePicture} width="100%" />}
                    </div> 

          <a className="btnNotification" href="#">
            <img
              src={require("../../../assets/Icons/bell.png")}
              alt="Notificaciones"
              className="iconAlerts"
            />
          </a>
        </div>*/}
        <HojaVida visibleTabs={this.state.permissionsTabs} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allyData: state.allyReducer.allyData,
    vacanteData2: state.vacanteReducer.vacanteData2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
