import React, { Component } from "react";
import {
 Card,
 CardBody,
 CardHeader,
 Col,
 Row,
 FormGroup,
 Form,
 Input,
 Label,
 CardFooter,
 Button,
 FormFeedback,
 FormText,
 TabContent,
 TabPane,
 Nav,
 NavItem,
 NavLink,
 Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./viewEmprendimiento.css";
import { uploadSingleImage } from "../../../services/uploadFile";
import { getAllCities } from "../../../redux/actions/citiesActions";
import {
 getEmprendimientoById,
 editEmprendimiento,
} from "../../../redux/actions/emprendActions";
import { Redirect } from "react-router-dom";
import classnames from "classnames";
//Utils
import filterCities from "../../../utils/departcities";

import swal from "sweetalert";

import axios from "axios";
import urls from "../../../apiConf";

class viewEmprendimiento extends Component {
 constructor(props) {
  super(props);
  this.state = this.setInitialData();
 }

 isJSON(str) {
  try {
   return JSON.parse(str) && !!str;
  } catch (e) {
   return false;
  }
 }

 componentDidMount = async () => {
  this.props.getAllCities();
  let emprendId = this.props.match.params.emprend;
  let facturasSAved = await this.get_Facturas(emprendId);
  let cuentassaved = await this.get_Otracuenta(emprendId);
  console.log(facturasSAved);
  this.props.getEmprendimientoById(emprendId).then((resp) => {
   console.log(resp.linkedPeople);
   this.setState({
    emprendId: emprendId,
    facturasSAved: facturasSAved,
    cuentassaved: cuentassaved,
    loadingData: false,
    fullName: resp.fullName,
    authorizingContact: resp.authorizingContact,
    authorizingDocument: resp.authorizingDocument,
    phone: resp.phone,
    cellphone: resp.cellphone,
    cellphoneTwo: resp.cellphoneTwo,
    city: resp.city,
    department: resp.department,
    neighborhood: resp.neighborhood,
    direction: resp.direction,
    sector: resp.sector,
    websiteLink: resp.websiteLink,
    permission: resp.permission,
    paymentSystem: resp.paymentSystem,
    observations: resp.observations,
    personsArr:
     resp.linkedPeople !== null || resp.linkedPeople !== "S/I"
      ? this.isJSON(resp.linkedPeople)
        ? JSON.parse(resp.linkedPeople)
        : []
      : [],
    documentationFiles:
     resp.dataSheet !== null ? JSON.parse(resp.dataSheet) : [],
    profilePicture: resp.profilePicture,
    emprendState: resp.state,
    idEmprend: resp.id,
    ally: resp.ally,
    citiesList: filterCities(resp.department),
    cuentas: [],
    facturas: [],
    cotizaciones: [],
   });
  });
 };

 setInitialData = () => {
  return {
   idEmprend: "",
   loadingData: true,
   ally: "",
   emprendState: 0,
   activeTab: "1",
   fullName: "",
   authorizingContact: "",
   authorizingDocument: "",
   phone: "",
   cellphone: "",
   cellphoneTwo: "",
   city: "",
   department: "",
   neighborhood: "",
   direction: "",
   sector: "",
   profilePicture: {},
   documentationFiles: [],
   websiteLink: "",
   citiesList: [],
   permission: "No",
   paymentSystem: "",
   observations: "",
   documentArr: [],
   personsArr: [
    {
     fullname: "",
     cellphone: 0,
     email: "",
     position: "",
    },
   ],
   imagePath: [],
   documents: "",
   errors: {
    photoErr: {},
    fullNameErr: {},
    phoneErr: {},
    documentErr: {},
    cellphoneErr: {},
    addressErr: {},
    sectorErr: {},
    authorizingContactErr: {},
    authorizingDocumentErr: {},
   },
  };
 };

 get_Facturas = async (empredn) => {
  console.log("GET Facturas");

  try {
   const reponseToken = await axios.post(
    `${urls.url_base}/dG9rZW4/`,
    urls.body,
    {
     headers: { "Content-Type": "application/json" },
    }
   );
   const token = await reponseToken.data.token;
   const responseAllyData = await axios.get(
    `${urls.url_base}/ZW1wcmU3/?emprendimiento=${empredn}`,
    { headers: { Authorization: `JWT ${token}` } }
   );
   const data = await responseAllyData.data.results;

   return data;
  } catch (err) {
   alert(
    `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
   );
  }
 };

 get_Otracuenta = async (empredn) => {
  console.log("GET Otracuenta");

  try {
   const reponseToken = await axios.post(
    `${urls.url_base}/dG9rZW4/`,
    urls.body,
    {
     headers: { "Content-Type": "application/json" },
    }
   );
   const token = await reponseToken.data.token;
   const responseAllyData = await axios.get(
    `${urls.url_base}/Y291cnNel/?emprendimiento=${empredn}`,
    { headers: { Authorization: `JWT ${token}` } }
   );
   const data = await responseAllyData.data.results;

   return data;
  } catch (err) {
   alert(
    `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
   );
  }
 };
 add_Otracuenta = (empredn) => {
  console.log("ADD");
  let dataObj = {
   ...this.state.cuentas[0],
   emprendimiento: urls.url_base + "/ZW1wcmU/" + empredn + "/",
  };
  return async (dispatch) => {
   try {
    const reponseToken = await axios.post(
     `${urls.url_base}/dG9rZW4/`,
     urls.body,
     {
      headers: { "Content-Type": "application/json" },
     }
    );
    const token = await reponseToken.data.token;
    const responseAllyData = await axios.post(
     `${urls.url_base}/Y291cnNel/`,
     dataObj,
     { headers: { Authorization: `JWT ${token}` } }
    );
    const data = await responseAllyData.data;
    if (data.length !== 0) {
     //dispatch(add_TracingNuevo(data));
     swal("Se ha creado el seguimiento correctamente!");
    } else {
     swal("Upss! No se ha creado el seguimiento!");
    }
   } catch (err) {
    alert(
     `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
    );
   }
  };
 };
 add_Factura = (empredn) => {
  console.log("ADD");
  let dataObj = {
   ...this.state.facturas[0],
   emprendimiento: urls.url_base + "/ZW1wcmU/" + empredn + "/",
  };
  return async (dispatch) => {
   try {
    const reponseToken = await axios.post(
     `${urls.url_base}/dG9rZW4/`,
     urls.body,
     {
      headers: { "Content-Type": "application/json" },
     }
    );
    const token = await reponseToken.data.token;
    const responseAllyData = await axios.post(
     `${urls.url_base}/ZW1wcmU3/`,
     dataObj,
     { headers: { Authorization: `JWT ${token}` } }
    );
    const data = await responseAllyData.data;
    if (data.length !== 0) {
     //dispatch(add_TracingNuevo(data));
     swal("Se ha creado el seguimiento correctamente!");
    } else {
     swal("Upss! No se ha creado el seguimiento!");
    }
   } catch (err) {
    alert(
     `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
    );
   }
  };
 };

 handleChangeFormValue = (e) => {
  const { name, value } = e.target;
  const index = e.target.getAttribute("data-index");
  let facturas = this.state.facturas;
  facturas[index][name] = value;
  this.setState({
   facturas: facturas,
  });
  console.log(this.state.facturas);
 };

 handleChangeFormValueOtras = (e) => {
  const { name, value } = e.target;
  const index = e.target.getAttribute("data-index");
  let cuentas = this.state.cuentas;
  cuentas[index][name] = value;
  this.setState({
   cuentas: cuentas,
  });
  console.log(this.state.cuentas);
 };

 handleChange = (e) => {
  const newState = this.state;
  newState[e.target.id] = e.target.value;
  console.log(e.target.id, e.target.value);
  if (e.target.id == "department") {
   this.state.citiesList = filterCities(e.target.value);
   this.state.city = this.state.citiesList[0];
  }
  this.setState(newState);
 };

 handleChangePositionsObj = (e, key, i) => {
  const newState = this.state;
  newState[e.target.id][i][key] = e.target.value;
  this.setState(newState);
 };

 handleChangeFile = (e) => {
  if (e.target.files[0]) {
   const imageFile = e.target.files[0];
   this.setState({
    imagePath: imageFile,
   });
  }
 };

 handleChangeMultiFile = (e, key, i) => {
  if (e.target.files[0]) {
   const imageFile = e.target.files[0];
   this.state.documentArr[i][key] = imageFile;
  }
 };

 addDocuments = () => {
  var tempDocs = this.state.documentArr;
  tempDocs.push({
   docName: "",
   path: [],
  });
  this.setState({
   documentArr: tempDocs,
  });
 };

 addPeople = () => {
  var tempPersons = this.state.personsArr;
  tempPersons.push({
   fullname: "",
   cellphone: 0,
   email: "",
   position: "",
  });
  this.setState({
   personsArr: tempPersons,
  });
 };

 addCuentas = () => {
  var cuentas = this.state.cuentas;
  cuentas.push({});
  this.setState({
   cuentas: cuentas,
  });
 };

 addFacturas = () => {
  var facturas = this.state.facturas;
  facturas.push({});
  this.setState({
   facturas: facturas,
  });
 };

 addCotizaciones = () => {
  var cotizaciones = this.state.cotizaciones;
  cotizaciones.push({});
  this.setState({
   cotizaciones: cotizaciones,
  });
 };

 rmPeople = (item, index) => {
  var tempPerson = this.state.personsArr;
  var i = tempPerson.indexOf(item);
  if (i !== -1) {
   tempPerson.splice(i, 1);
   this.setState({
    personsArr: tempPerson,
   });
  }
 };

 rmDocuments = (item, index) => {
  var tempDocs = this.state.documentArr;
  var i = tempDocs.indexOf(item);
  if (i !== -1) {
   tempDocs.splice(i, 1);
   this.setState({
    documentArr: tempDocs,
   });
  }
 };

 addPositions = () => {
  var tempPosition = this.state.positions;
  tempPosition.push({
   positionName: "",
   companyApply: "",
  });
  this.setState({
   positions: tempPosition,
  });
 };

 rmPositions = (item, index) => {
  if (this.state.positions.length > 1) {
   let tempPositions = this.state.positions;
   let i = tempPositions.indexOf(item);
   if (i !== -1) {
    tempPositions.splice(i, 1);
    this.setState({
     Positions: tempPositions,
    });
   }
  }
 };

 onSend = async () => {
  const isValid = this.validateForm();
  if (isValid) {
   this.checkInfoToSave();
  } else {
   swal(
    "Lo sentimos!",
    "Por favor verifica la información de los campos obligatorios antes de continuar.",
    "warning"
   );
   this.setState({
    activeTab: "1",
   });
  }
 };

 checkInfoToSave = () => {
  if (
   this.state.documentArr.length !== 0 &&
   this.state.documentArr[0].docName !== ""
  ) {
   var documents = [];
   this.state.documentArr.forEach(async (item, index) => {
    var data = await uploadSingleImage(item.path);
    documents.push({
     docName: item.docName,
     url: data.file,
    });

    if (this.state.documentArr.length === documents.length) {
     var tempDocs = this.state.documentationFiles;
     tempDocs.push(...documents);
     this.setState({
      documentationFiles: tempDocs,
     });
     this.saveEmprendimiento();
    }
   });
  } else {
   this.saveEmprendimiento();
  }
 };

 saveEmprendimiento = () => {
  console.log("saveEmprendimient");
  console.log(this.state);
  var objEmprend = {
   profilePicture: null,
   fullName: this.state.fullName,
   authorizingContact: this.state.authorizingContact,
   authorizingDocument: this.state.authorizingDocument,
   phone: this.state.phone,
   cellphone: this.state.cellphone,
   cellphoneTwo: this.state.cellphoneTwo,
   websiteLink: this.state.websiteLink,
   department: this.state.department,
   neighborhood: this.state.neighborhood,
   city: this.state.city,
   direction: this.state.direction,
   sector: this.state.sector,
   permission: this.state.permission,
   paymentSystem: this.state.paymentSystem,
   observations: this.state.observations,
   linkedPeople: JSON.stringify(this.state.personsArr),
   dataSheet: JSON.stringify(this.state.documentationFiles),
   ally: this.state.ally,
   state: parseInt(this.state.emprendState),
  };
  if (typeof this.state.imagePath === "object" && this.state.imagePath.name) {
   uploadSingleImage(this.state.imagePath).then((data) => {
    objEmprend.profilePicture = data.file;
    //Create emprendimiento
    this.props
     .editEmprendimiento(objEmprend, this.state.idEmprend)
     .then((resp) => {
      this.confirmSave();
     });
   });
  } else {
   //Create emprendimiento
   this.props
    .editEmprendimiento(objEmprend, this.state.idEmprend)
    .then((resp) => {
     this.confirmSave();
    });
  }
 };

 confirmSave = () => {
  swal(
   "Genial!",
   "El emprendimiento fue actualizado correctamente.",
   "success"
  );
 };

 toggleTab = (index) => {
  this.setState({
   activeTab: index,
  });
 };

 onNextTab = () => {
  let i = parseInt(this.state.activeTab);
  i = i + 1;
  this.setState({
   activeTab: i.toString(),
  });
 };

 validateForm = () => {
  const photoErr = {};
  const fullNameErr = {};
  const phoneErr = {};
  const cellphoneErr = {};
  const addressErr = {};
  const sectorErr = {};
  const authorizingContactErr = {};
  const authorizingDocumentErr = {};
  let isValid = true;

  if (this.state.fullName.trim().length === 0) {
   fullNameErr.required = "Por favor ingresa el nombre completo.";
   isValid = false;
  }
  if (this.state.authorizingContact.trim().length === 0) {
   authorizingContactErr.required = "Por favor ingresa un contacto autorizante";
   isValid = false;
  }
  /*  if (this.state.authorizingDocument.length === 0) {
   authorizingContactErr.required =
    "Por favor ingresa el documento del contacto autorizante";
   isValid = false;
  } */
  // if (this.state.phone.toString().length !== 7) {
  // 	phoneErr.maxLength = "Por favor ingresa un número de telefono valido.";
  // 	isValid = false;
  // }
  // if (this.state.cellphone.toString().length !== 10) {
  // 	cellphoneErr.required = "Número de celular no valido.";
  // 	isValid = false;
  // }
  if (this.state.direction.length === 0) {
   addressErr.required = "Por favor ingresa la dirección.";
   isValid = false;
  }
  if (this.state.sector === "") {
   sectorErr.required = "Por favor ingresa la información del sector.";
   isValid = false;
  }

  this.setState({
   errors: {
    photoErr,
    fullNameErr,
    // phoneErr,
    // cellphoneErr,
    addressErr,
    sectorErr,
    authorizingContactErr,
    authorizingDocumentErr,
   },
  });

  return isValid;
 };

 render() {
  if (!this.state.loadingData) {
   return (
    <div
     className="backgroundHojaVida"
     style={{ marginTop: "5%" }}>
     <Row style={{ width: "100%", justifyContent: "center" }}>
      <Col
       xs="12"
       md="12">
       <Card>
        <CardHeader>
         <strong>Emprendimiento</strong>
        </CardHeader>
        <CardBody style={{ overflowY: "scroll" }}>
         <Row style={{ marginBottom: 15 }}>
          <Col
           xs="12"
           md="4">
           <div className="form-group row">
            <label className="col-sm-10 col-form-label">Estado</label>
            <div className="col-sm-12">
             <select
              className="form-control"
              onChange={this.handleChange}
              id="emprendState"
              value={this.state.emprendState}>
              <option value="0">Inactivo</option>
              <option value="1">Activo</option>
             </select>
            </div>
           </div>
          </Col>
         </Row>
         <Nav tabs>
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "1",
            })}
            onClick={() => {
             this.toggleTab("1");
            }}>
            Información General Emprendimiento
           </NavLink>
          </NavItem>
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "2",
            })}
            onClick={() => {
             this.toggleTab("2");
            }}>
            Personas Vinculadas
           </NavLink>
          </NavItem>
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "3",
            })}
            onClick={() => {
             this.toggleTab("3");
            }}>
            Portafolio Producto / Servicio
           </NavLink>
          </NavItem>
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "5",
            })}
            onClick={() => {
             this.toggleTab("5");
            }}>
            Factura Electrónica
           </NavLink>
          </NavItem>
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "4",
            })}
            onClick={() => {
             this.toggleTab("4");
            }}>
            Otras Ventas
           </NavLink>
          </NavItem>
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "6",
            })}
            onClick={() => {
             this.toggleTab("6");
            }}>
            Cotización
           </NavLink>
          </NavItem>
         </Nav>
         <Form className="form-horizontal">
          <TabContent activeTab={this.state.activeTab}>
           <TabPane tabId="1">
            <br />
            <Row>
             <Col
              xs="12"
              md="6">
              <div className="form-group row containerFoto">
               <label className="col-12 col-form-label">
                Logo Emprendimiento
               </label>
               <div className="col-12 containerFile">
                <input
                 type="file"
                 accept="image/x-png,image/jpeg"
                 className="form-control-file"
                 disabled
                 id="profilePicture"
                 onChange={this.handleChangeFile}
                />
               </div>
              </div>
             </Col>
             <Col
              xs="12"
              md="6">
              {this.state.profilePicture !== null && (
               <div
                className="col-sm-2"
                style={{ marginTop: 15 }}>
                <img
                 src={this.state.profilePicture}
                 width="150px"
                 height="150px"
                />
               </div>
              )}
             </Col>
            </Row>
            <Row>
             <Col
              xs="12"
              md="6">
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Nombre del Emprendimiento
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa el nombre del emprendimiento"
                 id="fullName"
                 value={this.state.fullName}
                 onChange={this.handleChange}
                />
               </div>
               {Object.keys(this.state.errors.fullNameErr).map(
                (keyObj, index) => (
                 <div className="paddingTxtError">
                  <FormText
                   className="errorFormText"
                   key={index.toString()}>
                   {this.state.errors.fullNameErr[keyObj]}
                  </FormText>
                 </div>
                )
               )}
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Razon Social</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa la razon social"
                 id="fullName"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">RUT</label>
               <div className="col-sm-10">
                <input
                 type="file"
                 className="form-control"
                 placeholder=""
                 id="fullName"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Camara de Comercio
               </label>
               <div className="col-sm-10">
                <input
                 type="file"
                 className="form-control"
                 placeholder=""
                 id="fullName"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Numero de miembros{" "}
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa el numero de miembros"
                 id="fullName"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Contacto Autorizante
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa el contacto autorizante"
                 id="authorizingContact"
                 value={this.state.authorizingContact}
                 onChange={this.handleChange}
                />
               </div>
               {Object.keys(this.state.errors.authorizingContactErr).map(
                (keyObj, index) => (
                 <div className="paddingTxtError">
                  <FormText
                   className="errorFormText"
                   key={index.toString()}>
                   {this.state.errors.authorizingContactErr[keyObj]}
                  </FormText>
                 </div>
                )
               )}
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Documento Contacto Autorizante
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa el documento del contacto autorizante"
                 id="authorizingDocument"
                 value={this.state.authorizingDocument}
                 onChange={this.handleChange}
                />
               </div>
               {Object.keys(this.state.errors.authorizingDocumentErr).map(
                (keyObj, index) => (
                 <div className="paddingTxtError">
                  <FormText
                   className="errorFormText"
                   key={index.toString()}>
                   {this.state.errors.authorizingDocumentErr[keyObj]}
                  </FormText>
                 </div>
                )
               )}
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Teléfono Fijo</label>
               <div className="col-sm-10">
                <input
                 type="number"
                 className="form-control"
                 placeholder="Ingresa número de teléfono"
                 id="phone"
                 value={this.state.phone}
                 onChange={this.handleChange}
                />
               </div>
               {/* {Object.keys(this.state.errors.phoneErr).map((keyObj, index) => (
                                <div className="paddingTxtError">
                                  <FormText className="errorFormText" key={index.toString()}>{this.state.errors.phoneErr[keyObj]}</FormText>
                                </div>
                              ))} */}
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Celular</label>
               <div className="col-sm-10">
                <input
                 type="number"
                 min="0"
                 className="form-control"
                 placeholder="Ingresa el número de celular"
                 id="cellphone"
                 value={this.state.cellphone}
                 onChange={this.handleChange}
                />
               </div>
               {/* {Object.keys(this.state.errors.cellphoneErr).map((keyObj, index) => (
                                <div className="paddingTxtError">
                                  <FormText className="errorFormText" key={index.toString()}>{this.state.errors.cellphoneErr[keyObj]}</FormText>
                                </div>
                              ))} */}
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Celular 2</label>
               <div className="col-sm-10">
                <input
                 type="number"
                 min="0"
                 className="form-control"
                 placeholder="Ingresa otro número de celular"
                 id="cellphoneTwo"
                 value={this.state.cellphoneTwo}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Página Web</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Link de la página web"
                 id="websiteLink"
                 value={this.state.websiteLink}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Redes Sociales
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Link de la Red Social"
                 id="websiteLink"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Qué le falta para estar legalmente constituido
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder=""
                 id="websiteLink"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Proceso de cobro
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder=""
                 id="websiteLink"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Logística de Envíos
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder=""
                 id="websiteLink"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Capacidad de producción / semana
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder=""
                 id="websiteLink"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                ¿Cuenta con equipos de producción?
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder=""
                 id="websiteLink"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                ¿Cuenta con costeo de productos?
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder=""
                 id="websiteLink"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                ¿Tiene registro INVIMA?
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder=""
                 id="websiteLink"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Qué tipo de registro Invima requiere
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder=""
                 id="websiteLink"
                />
               </div>
              </div>
             </Col>
             <Col
              xs="12"
              md="6">
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Departamento</label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="department"
                 onChange={this.handleChange}
                 value={this.state.department}>
                 <option defaultValue="">Seleccione el departamento</option>
                 <option value="Amazonas">Amazonas</option>
                 <option value="Antioquia">Antioquia</option>
                 <option value="Arauca">Arauca</option>
                 <option value="Atlántico">Atlántico</option>
                 <option value="Bogotá">Bogotá DC</option>
                 <option value="Bolívar">Bolívar</option>
                 <option value="Boyacá">Boyacá</option>
                 <option value="Caldas">Caldas</option>
                 <option value="Caquetá">Caquetá</option>
                 <option value="Casanare">Casanare</option>
                 <option value="Cauca">Cauca</option>
                 <option value="Cesar">Cesar</option>
                 <option value="Chocó">Chocó</option>
                 <option value="Córdoba">Córdoba</option>
                 <option value="Cundinamarca">Cundinamarca</option>
                 <option value="Güainia">Güainia</option>
                 <option value="Guaviare">Guaviare</option>
                 <option value="Huila">Huila</option>
                 <option value="La Guajira">La Guajira</option>
                 <option value="Magdalena">Magdalena</option>
                 <option value="Meta">Meta</option>
                 <option value="Nariño">Nariño</option>
                 <option value="Norte de Santander">Norte de Santander</option>
                 <option value="Putumayo">Putumayo</option>
                 <option value="Quindío">Quindío</option>
                 <option value="Risaralda">Risaralda</option>
                 <option value="San Andrés y Providencia">
                  San Andrés y Providencia
                 </option>
                 <option value="Santander">Santander</option>
                 <option value="Sucre">Sucre</option>
                 <option value="Tolima">Tolima</option>
                 <option value="Valle del Cauca">Valle del Cauca</option>
                 <option value="Vaupés">Vaupés</option>
                 <option value="Vichada">Vichada</option>
                </select>
               </div>
              </div>
              {this.state.citiesList && (
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">Ciudad</label>
                <div className="col-sm-10">
                 <select
                  className="form-control"
                  id="city"
                  onChange={this.handleChange}
                  onSelect={this.handleChange}
                  value={this.state.city}>
                  <option
                   defaultValue=""
                   disabled>
                   Seleccione la ciudad
                  </option>
                  {this.state.citiesList.length != 0
                   ? this.state.citiesList.map((item, index) => {
                      return (
                       <option
                        key={index.toString()}
                        value={item}>
                        {item}
                       </option>
                      );
                     })
                   : null}
                 </select>
                </div>
               </div>
              )}
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Sector o Barrio
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="indica el sector o barrio"
                 id="neighborhood"
                 value={this.state.neighborhood}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Dirección</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa dirección o ubicación emprendimiento"
                 id="direction"
                 value={this.state.direction}
                 onChange={this.handleChange}
                />
               </div>
               {Object.keys(this.state.errors.addressErr).map(
                (keyObj, index) => (
                 <div className="paddingTxtError">
                  <FormText
                   className="errorFormText"
                   key={index.toString()}>
                   {this.state.errors.addressErr[keyObj]}
                  </FormText>
                 </div>
                )
               )}
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Sector Económico
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="sector"
                 onChange={this.handleChange}
                 value={this.state.sector}>
                 <option defaultValue="">Seleccionar sector</option>
                 <option>Estampaciones</option>
                 <option>Cerveza</option>
                 <option>Miel</option>
                 <option>Artesanias en mostacilla</option>
                 <option>Confecciones</option>
                 <option>Café</option>
                 <option>Publicidad, confecciones y estampaci</option>
                 <option>Gelatinas de pata de res</option>
                 <option>Huevos</option>
                 <option>Miel polen, propoleo y Café</option>
                 <option>Velas decorativas</option>
                 <option>Chocolates</option>
                 <option>Ropa femenina</option>
                 <option>Nueces de Sachainchi</option>
                 <option>Miel y frutas deshidratadas</option>
                 <option>Achiras</option>
                 <option>
                  Impresiones 3D (Prromocionales, Suculentas,bisuteri
                 </option>
                 <option>Jabones artesanales</option>
                 <option>Productos en madera</option>
                 <option>Orellanas</option>
                 <option>Canastas/Bolsos</option>
                 <option>Publicidad</option>
                 <option>Artesanias de emberas</option>
                 <option>Productos y servicios de limpie</option>
                 <option>S/I</option>
                 <option>Café, Miel y Aji</option>
                 <option>Estampados y sublimaciones</option>
                </Input>
               </div>
               {Object.keys(this.state.errors.sectorErr).map(
                (keyObj, index) => (
                 <div className="paddingTxtError">
                  <FormText
                   className="errorFormText"
                   key={index.toString()}>
                   {this.state.errors.sectorErr[keyObj]}
                  </FormText>
                 </div>
                )
               )}
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Permiso</label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission"
                 onChange={this.handleChange}
                 value={this.state.permission}>
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Afiliado EPS - ARL
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission">
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Productos del Campo
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission">
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Tienda de la empatía
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission">
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Catálogo interno Alianza Soluciones
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission">
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Desde la Raíz</label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission">
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Se le Tiene ANDI
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission">
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Priorizados Alianza soluciones
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission">
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                ¿Recibió Beneficio económico de ARN?
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission">
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Declara IVA</label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="permission">
                 <option value="Si">Si</option>
                 <option value="No">No</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Formalizado (sistemas de pago)
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa el sistema o forma de pago"
                 id="paymentSystem"
                 value={this.state.paymentSystem}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Antecedentes</label>
               <div className="col-sm-10">
                <Input
                 type="textarea"
                 rows="4"
                 className="form-control"
                 placeholder="Antecedentes"
                 id="observations"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Observaciones generales por parte de Alianza Soluciones
               </label>
               <div className="col-sm-10">
                <Input
                 type="textarea"
                 rows="4"
                 className="form-control"
                 placeholder="Observaciones generales"
                 id="observations"
                 value={this.state.observations}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Cursos - Quienes lo han formado
               </label>
               <div className="col-sm-10">
                <Input
                 type="textarea"
                 rows="4"
                 className="form-control"
                 placeholder=""
                 id="observations"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Necesidades según emprendedor
               </label>
               <div className="col-sm-10">
                <Input
                 type="textarea"
                 rows="4"
                 className="form-control"
                 placeholder=""
                 id="observations"
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Que mentorías se proponen
               </label>
               <div className="col-sm-10">
                <Input
                 type="textarea"
                 rows="4"
                 className="form-control"
                 placeholder=""
                 id="observations"
                />
               </div>
              </div>
             </Col>
            </Row>
           </TabPane>
           <TabPane tabId="2">
            <br />
            <div
             className="row containerPlus"
             onClick={() => this.addPeople()}>
             <Button
              color="info"
              type="button">
              Agregar
             </Button>
            </div>
            <Row>
             {this.state.personsArr.map((item, index) => (
              <Col
               xs="12"
               md="6"
               key={index.toString()}>
               <div
                className="contentCloseDocuments"
                style={{ width: "85%", cursor: "pointer" }}
                onClick={() => this.rmPeople(item, index)}>
                <a className="btnPlus">
                 <img
                  src={require("../../../assets/Icons/close.png")}
                  alt="Eliminar"
                  className="iconPlus"
                 />
                </a>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Nombre Completo
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder="Ingresa nombre completo"
                  id="personsArr"
                  value={item.fullname}
                  onChange={(e) =>
                   this.handleChangePositionsObj(e, "fullname", index)
                  }
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">Celular</label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder="Ingresa el número de celular"
                  id="personsArr"
                  value={item.cellphone}
                  onChange={(e) =>
                   this.handleChangePositionsObj(e, "cellphone", index)
                  }
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Correo electronico
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder="Ingresa el correo electrónico"
                  id="personsArr"
                  value={item.email}
                  onChange={(e) =>
                   this.handleChangePositionsObj(e, "email", index)
                  }
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Cargo en emprendimiento
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder="Ingresa el cargo"
                  id="personsArr"
                  value={item.position}
                  onChange={(e) =>
                   this.handleChangePositionsObj(e, "position", index)
                  }
                 />
                </div>
               </div>
               <hr></hr>
              </Col>
             ))}
            </Row>
           </TabPane>
           <TabPane tabId="3">
            <br />
            <Row>
             <Col
              xs="12"
              md="6">
              <label
               className="col-12"
               htmlFor="exampleFormControlFile1">
               Listado de productos o servicios
              </label>
              {this.state.documentationFiles.map((item, index) => {
               return (
                <div
                 className="form-group row containerArchivos"
                 key={index.toString()}>
                 <div className="col-sm-10 containerFileDocuments">
                  <div className="row contentDocumentos">
                   <div className="col-sm-6">
                    <select
                     className="form-control"
                     id="documentArr"
                     value={item.docName}
                     disabled
                     onChange={(e) =>
                      this.handleChangePositionsObj(e, "docName", index)
                     }>
                     <option value="producto">Producto</option>
                     <option value="servicio">Servicio</option>
                    </select>
                   </div>

                   <div className="col-sm-6">
                    <a
                     href={item.url}
                     target="blank"
                     style={{ color: "#000" }}>
                     Ver documento
                    </a>
                   </div>
                  </div>
                 </div>
                </div>
               );
              })}
             </Col>
             <Col
              xs="12"
              md="6">
              <div
               className="row containerPlus"
               onClick={() => this.addDocuments()}>
               <label
                className="col-10"
                htmlFor="exampleFormControlFile1">
                Cargar producto o servicio
               </label>
               <Button
                type="button"
                color="info"
                className="addDoc">
                +
               </Button>
              </div>
              {this.state.documentArr.map((item, index) => {
               return (
                <div
                 className="form-group row containerArchivos"
                 key={index.toString()}>
                 <div className="col-sm-10 containerFileDocuments">
                  <div
                   className="contentCloseDocuments"
                   onClick={() => this.rmDocuments(item, index)}>
                   <a className="btnPlus">
                    <img
                     src={require("../../../assets/Icons/close.png")}
                     alt="Eliminar"
                     className="iconPlus"
                    />
                   </a>
                  </div>
                  <div className="row contentDocumentos">
                   <div className="col-sm-6">
                    <select
                     className="form-control"
                     id="documentArr"
                     value={item.docName}
                     onChange={(e) =>
                      this.handleChangePositionsObj(e, "docName", index)
                     }>
                     <option
                      value=""
                      disabled
                      selected>
                      Selecciona archivo
                     </option>
                     <option value="producto">Producto</option>
                     <option value="servicio">Servicio</option>
                    </select>
                   </div>
                   <div className="col-sm-6">
                    <input
                     type="file"
                     className="form-control-file"
                     id="exampleFormControlFile1"
                     onChange={(e) =>
                      this.handleChangeMultiFile(e, "path", index)
                     }
                    />
                   </div>
                  </div>
                 </div>
                </div>
               );
              })}
             </Col>
            </Row>
           </TabPane>
           <TabPane tabId="4">
            <br />
            <h3>Otras Ventas Guardadas</h3>
            <div
             style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "50px",
             }}>
             {this.state.cuentassaved.map((item, index) => (
              <div>
               {item.fecha !== null && <p>fecha: {item.fecha}</p>}
               {item.tipo !== null && <p>tipo: {item.tipo}</p>}
               {item.descripcion !== null && (
                <p>descripcion: {item.descripcion}</p>
               )}
               {item.valor !== null && <p>valor: {item.valor}</p>}
               {item.fechaelaboracion !== null && (
                <p>fechaelaboracion: {item.fechaelaboracion}</p>
               )}
               {item.observacion !== null && (
                <p>observacion: {item.observacion}</p>
               )}
              </div>
             ))}
            </div>
            <div
             className="row containerPlus"
             onClick={() => this.addCuentas()}>
             <Button
              color="info"
              type="button">
              Agregar
             </Button>
            </div>
            <Row>
             {this.state.cuentas.map((item, index) => (
              <Col
               xs="12"
               md="6"
               key={index.toString()}>
               <div
                className="contentCloseDocuments"
                style={{ width: "85%", cursor: "pointer" }}>
                <a className="btnPlus">
                 <img
                  src={require("../../../assets/Icons/close.png")}
                  alt="Eliminar"
                  className="iconPlus"
                 />
                </a>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Fecha de la venta o prestación del servicio{" "}
                </label>
                <div className="col-sm-10">
                 <input
                  type="date"
                  className="form-control"
                  placeholder=""
                  name="fecha"
                  data-index={index}
                  onChange={this.handleChangeFormValueOtras}
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Tipo (Evento, Feria o Cliente)
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  name="tipo"
                  data-index={index}
                  onChange={this.handleChangeFormValueOtras}
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Descripción de bienes o servicios prestados{" "}
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  name="descripcion"
                  data-index={index}
                  onChange={this.handleChangeFormValueOtras}
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Valor total de la transacción{" "}
                </label>
                <div className="col-sm-10">
                 <input
                  type="number"
                  className="form-control"
                  placeholder=""
                  name="valor"
                  data-index={index}
                  onChange={this.handleChangeFormValueOtras}
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Fecha de elaboración de la cuenta de cobro{" "}
                </label>
                <div className="col-sm-10">
                 <input
                  type="date"
                  className="form-control"
                  placeholder=""
                  name="fechaelaboracion"
                  data-index={index}
                  onChange={this.handleChangeFormValueOtras}
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">Observación</label>
                <div className="col-sm-10">
                 <textarea
                  type="text"
                  className="form-control"
                  placeholder=""
                  name="observacion"
                  data-index={index}
                  onChange={this.handleChangeFormValueOtras}
                  rows={3}></textarea>
                </div>
               </div>
               <Button onClick={this.add_Otracuenta(this.state.emprendId)}>
                Guardar Otra Cuenta
               </Button>

               <hr></hr>
              </Col>
             ))}
            </Row>
           </TabPane>
           <TabPane tabId="5">
            <br />
            <h3>Facturas Guardadas</h3>
            <div
             style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "50px",
             }}>
             {this.state.facturasSAved.map((item, index) => (
              <div>
               {item.numeracion !== null && (
                <p>numeracion: {item.numeracion}</p>
               )}
               {item.razonsocial !== null && (
                <p>razonsocial: {item.razonsocial}</p>
               )}
               {item.nit !== null && <p>nit: {item.nit}</p>}
               {item.cuenta !== null && <p>cuenta: {item.cuenta}</p>}
               {item.titularcuenta !== null && (
                <p>titularcuenta: {item.titularcuenta}</p>
               )}
               {item.titularcedula !== null && (
                <p>titularcedula: {item.titularcedula}</p>
               )}
               {item.clienteindirecto !== null && (
                <p>clienteindirecto: {item.clienteindirecto}</p>
               )}
               {item.nitclienteindirecto !== null && (
                <p>nitclienteindirecto: {item.nitclienteindirecto}</p>
               )}
               {item.fechafactura !== null && (
                <p>fechafactura: {item.fechafactura}</p>
               )}
               {item.valor !== null && <p>valor: {item.valor}</p>}
               {item.formapago !== null && <p>formapago: {item.formapago}</p>}
               {item.descripcion !== null && (
                <p>descripcion: {item.descripcion}</p>
               )}
               {item.impuestos !== null && <p>impuestos: {item.impuestos}</p>}
               {item.calidadretenedor !== null && (
                <p>calidadretenedor: {item.calidadretenedor}</p>
               )}
              </div>
             ))}
            </div>
            <br />
            <div
             className="row containerPlus"
             onClick={() => this.addFacturas()}>
             <Button
              color="info"
              type="button">
              Agregar
             </Button>
            </div>
            <Row>
             {this.state.facturas.map((item, index) => (
              <Col
               xs="12"
               md="12"
               key={index.toString()}>
               <div
                className="contentCloseDocuments"
                style={{ width: "100%", cursor: "pointer" }}>
                <a className="btnPlus">
                 <img
                  src={require("../../../assets/Icons/close.png")}
                  alt="Eliminar"
                  className="iconPlus"
                 />
                </a>
               </div>
               <Row>
                <Col
                 xs="6"
                 md="6">
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">Numeración</label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="numeracion"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Apellidos y nombres o razón social
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="razonsocial"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Número de NIT del adquiriente (Cedula si es particular).{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="nit"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Cliente Directo
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="checkbox"
                    className="form-control"
                    placeholder=""
                    id="personsArr"
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Cliente Indirecto
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="clienteindirecto"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   NIT Cliente Indirecto
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="nitclienteindirecto"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Fecha expedición factura electrónica.{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="date"
                    className="form-control"
                    placeholder=""
                    name="fechafactura"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Valor total de la operación.{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    name="valor"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Forma de pago.{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="formapago"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Descripción de los bienes vendidos o los servicios prestados.{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="descripcion"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Impuestos de la transacción.{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="impuestos"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>

                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Calidad de retenedor del impuesto sobre las ventas.{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="calidadretenedor"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                </Col>
                <Col
                 xs="6"
                 md="6">
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Cuenta Bancaria.{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    name="cuenta"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Titular Cuenta.{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="titularcuenta"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Cedula o NIT Titular.{" "}
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="titularcedula"
                    data-index={index}
                    onChange={this.handleChangeFormValue}
                   />
                  </div>
                 </div>

                 <hr></hr>

                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Soporte Pago
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="file"
                    className="form-control"
                    placeholder=""
                    id="personsArr"
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Cuenta Cobro
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="file"
                    className="form-control"
                    placeholder=""
                    id="personsArr"
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                   Factura Electronica
                  </label>
                  <div className="col-sm-10">
                   <input
                    type="file"
                    className="form-control"
                    placeholder=""
                    id="personsArr"
                   />
                  </div>
                 </div>
                 <div className="form-group row">
                  <label className="col-sm-12 col-form-label">Pagada</label>
                  <div className="col-sm-10">
                   <input
                    type="checkbox"
                    className="form-control"
                    placeholder=""
                    id="personsArr"
                   />
                  </div>
                 </div>
                 <Button onClick={this.add_Factura(this.state.emprendId)}>
                  Guardar Factura
                 </Button>
                </Col>
               </Row>
              </Col>
             ))}
            </Row>
           </TabPane>
           <TabPane tabId="6">
            <br />
            <div
             className="row containerPlus"
             onClick={() => this.addCotizaciones()}>
             <Button
              color="info"
              type="button">
              Agregar
             </Button>
            </div>
            <Row>
             {this.state.cotizaciones.map((item, index) => (
              <Col
               xs="12"
               md="6"
               key={index.toString()}>
               <div
                className="contentCloseDocuments"
                style={{ width: "85%", cursor: "pointer" }}>
                <a className="btnPlus">
                 <img
                  src={require("../../../assets/Icons/close.png")}
                  alt="Eliminar"
                  className="iconPlus"
                 />
                </a>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">Fecha</label>
                <div className="col-sm-10">
                 <input
                  type="date"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Cliente Directo
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Cliente Indirecto
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>

               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Producto a cotizar{" "}
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Número de unidades:{" "}
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Valor por unidad:{" "}
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">Valor total:</label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">IVA:</label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                 Valor del envío, en caso de que aplique
                </label>
                <div className="col-sm-10">
                 <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>
               <div className="form-group row">
                <label className="col-sm-12 col-form-label">Aprobada</label>
                <div className="col-sm-10">
                 <input
                  type="checkbox"
                  className="form-control"
                  placeholder=""
                  id="personsArr"
                 />
                </div>
               </div>

               <hr></hr>
              </Col>
             ))}
            </Row>
           </TabPane>
          </TabContent>
         </Form>
        </CardBody>
        <CardFooter>
         <div className="col-12 containerBtnEnviar">
          <button
           className="btn-success btnEnviar"
           onClick={() => this.onSend()}>
           ACTUALIZAR
          </button>
         </div>
         {/* {this.state.activeTab != "3" && <div className="col-12 containerBtnEnviar">
                    <button className="btn-success btnEnviar" onClick={() => this.onNextTab()}>
                      SIGUIENTE
                    </button>
                  </div>} */}
        </CardFooter>
       </Card>
      </Col>
     </Row>
    </div>
   );
  } else {
   return (
    <div className="backgroundHojaVida">
     <Row style={{ width: "100%", alignItems: "center", marginTop: "20%" }}>
      <Spinner
       type="grow"
       size="sm"
       color="info"
      />{" "}
      <Spinner
       type="grow"
       color="info"
      />{" "}
      <Spinner
       type="grow"
       size="sm"
       color="info"
      />{" "}
     </Row>
    </div>
   );
  }
 }
}

const mapStateToProps = (state) => {
 return {
  citiesData: state.citiesReducer.citiesData,
  allyData: state.allyReducer.allyData,
  isSaveEmprend: state.emprendimientoReducer.isSaveEmprend,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  getAllCities: bindActionCreators(getAllCities, dispatch),
  getEmprendimientoById: bindActionCreators(getEmprendimientoById, dispatch),
  editEmprendimiento: bindActionCreators(editEmprendimiento, dispatch),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(viewEmprendimiento);
